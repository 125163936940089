import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Swal from "sweetalert2";
import "./crud.css";
import Form from 'react-bootstrap/Form';
import qr from '../../../resorce/lineQrcode.png'
import axiosClient from "../../../util/AxiosClient";

export default function ListClaimRecord() {
  const [claimRecords, setClaimRecords] = useState([]);
  const [trackingNumbers, setTrackingNumbers] = useState({});
  const [shippingCompanies, setShippingCompanies] = useState({});
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    fetchClaimRecords();
  }, []);

  const fetchClaimRecords = async () => {
    try {
      const response = await axiosClient.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/claim-record/`,
        {
          withCredentials: true,
        }
      );
      const res = response.data.data
      const trackingNumbersInitial = res.reduce((acc, curr) => {
        acc[curr._id] = curr.trackingNumber || '';
        return acc;
      }, {});
      const shippingCompaniesInitial = res.reduce((acc, curr) => {
        acc[curr._id] = curr.shippingCompany || 'Flash';
        return acc;
      }, {});
      setTrackingNumbers(trackingNumbersInitial);
      setShippingCompanies(shippingCompaniesInitial);
      setClaimRecords(res);
      return res
    } catch (error) {
      console.log(error);
      // Handle login failure
    }
  };

  const toggleShippingStatus = async (id) => {
    const claimRecord = claimRecords.find(prize => prize._id === id)
    const prizeIsEnableStatus = claimRecord.status === 'shipping' || false
    const statusToPersis = !prizeIsEnableStatus ? "shipping" : 'pending'
    const isConfirm = await Swal.fire({
      title: `Are you sure to togle shipping status?`,
      text: `set to ${statusToPersis}`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, toggle!",
    }).then((result) => {
      return result.isConfirmed;
    });

    if (!isConfirm) {
      return;
    }

    await axiosClient
      .put(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/claim-record/${id}`,
        {
          status: statusToPersis
        }
      )
      .then(({ data }) => {
        Swal.fire({
          icon: "success",
          text: data.message,
        });
        fetchClaimRecords();
      })
      .catch(({ response: { data } }) => {
        Swal.fire({
          text: data.message,
          icon: "error",
        });
      });
  };

  const handlePrint = (record) => {
    const printContents = `
      <style>
        @page { 
          margin: 0; 
        }
        body { 
          margin: 0.7cm; /* Adjust if you need small margins */
        }
      </style>
      <div style="page-break-after: always;">
        <h3>กรุณานำส่ง</h3>
        <p><strong>ชื่อ:</strong> ${record.shippingName}</p>
        <p><strong>ที่อยู่:</strong> ${record.address}</p>
        <p><strong>เบอร์โทร:</strong> ${record.tel}</p>
        <p><strong>อีเมล:</strong> ${record.email}</p>
        <p><strong>ref:</strong> ${record._id}</p>
      </div>
      <div style="page-break-after: always;">
        <h3>รายการสินค้าที่ต้องได้รับ:</h3>
        <ul>
          ${record.items.sort().map(i => `<li>${"("+i.split(" ").slice(0,1)+") "+i.split(" ").slice(1,-1).join(" ")+" "+i.split(" ").slice(-1)}</li>`).join('')}
        </ul>
        <p><strong>ref:</strong> ${record._id}</p>
        <h4>สแกนไลน์เพื่อนเล่นกิจกรรมรับเหรียญฟรี<h4>
        <img id='qrCode' style="height: 100px; width: 100px; margin: 0 10px;" src=${qr} alt="QR Code">
      </div>
    `;
  
    const printWindow = window.open('', '', 'height=900,width=1200');
    printWindow.document.write('<html><head></head><body>');
    printWindow.document.write(printContents);
    printWindow.document.write('</body></html>');
    printWindow.document.close();
    printWindow.focus();
  
    // Wait for the image to load before closing the print window
    const img = printWindow.document.getElementById('qrCode');
    img.onload = () => {
      printWindow.print();
      printWindow.close();
    };
    
    img.onerror = () => {
      console.error('Image failed to load.');
      printWindow.print();
      printWindow.close();
    };
  };

  const handleTrackingNumberChange = (e, id) => {
    setTrackingNumbers({
      ...trackingNumbers,
      [id]: e.target.value,
    });
  };

  const handleShippingCompanyChange = (e, id) => {
    setShippingCompanies({
      ...shippingCompanies,
      [id]: e.target.value,
    });
  };

  const updateTrackingNumber = async (id) => {
    if (isLoading) {
      return
    }
    
    const isConfirm = await Swal.fire({
      title: `Are you sure to send tractingNumber?`,
      text: `บริษัทขนส่ง: ${shippingCompanies[id]} หมายเลข tracking: ${trackingNumbers[id]}`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, send!",
    }).then((result) => {
      return result.isConfirmed;
    });

    if (!isConfirm) {
      return;
    }
    setIsLoading(true)
    try {
      await axiosClient.put(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/claim-record/${id}`,
        {
          trackingNumber: trackingNumbers[id],
          shippingCompany: shippingCompanies[id],
        }
      );
      Swal.fire({
        icon: "success",
        text: "Tracking number updated successfully",
      });
      fetchClaimRecords();
    } catch (error) {
      Swal.fire({
        text: "Failed to update tracking number",
        icon: "error",
      });
    } finally {
      setIsLoading(false)
    }
  };
  

  return (
      <div className="crud-container">
        <div className="row">
          <div className="col-12">
            <div className="table-responsive">
              <table className="table table-bordered mb-0 text-center">
                <thead>
                  <tr>
                    <th>Is Shipping</th>
                    <th>Status</th>
                    <th>Tracking Number</th>
                    <th>ref</th>
                    <th>Shipping Name</th>
                    <th>Address</th>
                    <th>Tel</th>
                    <th>Email</th>
                    <th>Items</th>
                    <th>Created At</th>
                    <th>Print</th>
                  </tr>
                </thead>
                <tbody>
                  {claimRecords.length > 0 &&
                    claimRecords.map((row, key) => (
                      <tr key={row._id}>
                        <td>
                          <Form>
                            <Form.Check // prettier-ignore
                              type="switch"
                              checked = {row.status === 'shipping' ||false}
                              onClick={()=>{
                                toggleShippingStatus(row._id)
                              }}
                            />
                          </Form>
                        </td>
                        <td style={{color:row.status === 'shipping'?'green':'red'}}>
                        {row.status}
                        </td>
                        <td>
                          {row.trackingNumber ? (
                            <>
                              <>บริษัทขนส่ง: {row.shippingCompany ?? '-'}</>
                              <br/>
                              <>เลขพัสดุ: {row.trackingNumber}</>
                            </>
                          ) : (
                            <Form onSubmit={(e) => {
                              e.preventDefault();
                              updateTrackingNumber(row._id);
                            }}>
                              <div>
                                <div>
                                  <Form.Label>บริษัทขนส่ง</Form.Label>
                                  <br/>
                                  <Form.Select
                                    value={shippingCompanies[row._id] || 'Flash'}
                                    onChange={(e) => handleShippingCompanyChange(e, row._id)}
                                  >
                                    <option value="Flash">Flash</option>
                                    <option value="Kerry">Kerry</option>
                                    <option value="J&T">J&T</option>
                                    <option value="ไปรษณีย์ไทย">ไปรษณีย์ไทย</option>
                                    <option value="SPX Express">SPX Express</option>
                                  </Form.Select>
                                  <br/>
                                  <Form.Label>กรอกเลขพัสดุ</Form.Label>
                                  <Form.Control
                                    type="text"
                                    value={trackingNumbers[row._id] || ''}
                                    onChange={(e) => handleTrackingNumberChange(e, row._id)}
                                  />
                                  
                                </div>
                                <Button type="submit" variant="primary" className="mt-2" disabled={shippingCompanies[row._id].length === 0 || trackingNumbers[row._id].length === 0}>
                                  ส่งเมลเลข tracking
                                </Button>
                              </div>
                            </Form>
                          )}
                        </td>
                        <td>{row._id}</td>
                        <td>{row.shippingName}</td>
                        <td>{row.address || '-'}</td>
                        <td>{row.tel}</td>
                        <td>{row.email}</td>
                        <td>{row.items.sort().map(i=><li>{"("+i.split(" ").slice(0,1)+")"+i.split(" ").slice(1,-1).join("")+" "+new Date(Number(i.split(" ").slice(-1))).toISOString().slice(0, 19).replace('T', ' ')}</li>) ?? "-"}</td>
                        <td>{row?.createdAt?.split('T')?.[0] || '-'}</td>
                        <td>
                          <Button onClick={() => handlePrint(row)}>Print Label</Button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
  );
}
