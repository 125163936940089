import React, { useEffect, useState } from "react";
import "./joystick.css";
import useSound from "use-sound";
import directionReleaseSfx from "../../resorce/mixkit-quick-jump-arcade-game-239.wav";
import directionSfx from "../../resorce/mixkit-arcade-game-jump-coin-216.wav";
import buttonSfx from "../../resorce/mixkit-repeating-arcade-beep-1084.wav";
import machineNoiceSfx from "../../resorce/mechanicalclamp.mp3";

const Joystick = ({ ws, machineID, setImgNumber }) => {
  const [isButtonPressed, setIsButtonPressed] = useState({
    up: false,
    down: false,
    left: false,
    right: false,
  });
  const [directionEffect] = useSound(directionSfx, {
    interrupt: true,
    volume: 0.25,
  });
  const [buttonEffect] = useSound(buttonSfx, { interrupt: true, volume: 0.25 });
  const [machineNoiceEffect, { stop }] = useSound(machineNoiceSfx, {
    interrupt: true,
    volume: 0.25,
    loop: true,
  });
  const [directionReleaseEffect] = useSound(directionReleaseSfx, {
    interrupt: true,
    volume: 0.25,
  });
  const handleButtonPress = async (direction, event) => {
    // event.preventDefault();
    // console.log(direction);
    setIsButtonPressed((prevState) => ({
      ...prevState,
      [direction]: true,
    }));
    if (direction != "button") {
      directionEffect();
      machineNoiceEffect();
    } else {
      buttonEffect();
    }

    try {
      if (ws) {
        ws.send(
          `CLIENT remote ${machineID} on_button ${direction} on ${
            JSON.parse(localStorage.getItem("user"))._id
          }`
        );
      }
    } catch (error) {
      console.error("Error:", error);
    }

    // try {
    //   const response = await axiosClient.put(`${baseURL}/on`, data);
    //   console.log("Response:", response.data.data);
    // } catch (error) {
    //   console.error("Error:", error);
    // }
  };

  const handleButtonRelease = async (direction, event) => {
    event.preventDefault();
    // console.log(direction);
    setIsButtonPressed((prevState) => ({
      ...prevState,
      [direction]: false,
    }));
    setTimeout(function () {
      if (direction != "button") {
        directionReleaseEffect();
        stop();
      }

      try {
        ws.send(
          `CLIENT remote ${machineID} on_button ${direction} off ${
            JSON.parse(localStorage.getItem("user"))._id
          }`
        );
      } catch (error) {
        console.error("Error:", error);
      }
    }, 100);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      switch (event.code) {
        case "KeyW":
          if (!isButtonPressed.up) handleButtonPress("up",event);
          console.log(event.code, "down")
          break;
        case "KeyA":
          if (!isButtonPressed.left) handleButtonPress("left",event);
          console.log(event.code, "down")

          break;
        case "KeyS":
          if (!isButtonPressed.down) handleButtonPress("down",event);
          console.log(event.code, "down")

          break;
        case "KeyD":
          if (!isButtonPressed.right) handleButtonPress("right",event);
          console.log(event.code, "down")

          break;
        case "Space":
          event.preventDefault();
          if (!isButtonPressed.button) handleButtonPress("button",event);
          console.log(event.code, "down")

          break;
        default:
          break;
      }
    };

    const handleKeyUp = (event) => {
      switch (event.code) {
        case "KeyW":
          handleButtonRelease("up",event);
          console.log(event.code, "up")

          break;
        case "KeyA":
          handleButtonRelease("left",event);
          console.log(event.code, "up")

          break;
        case "KeyS":
          handleButtonRelease("down",event);
          console.log(event.code, "up")

          break;
        case "KeyD":
          handleButtonRelease("right",event);
          console.log(event.code, "up")

          break;
        case "Space":
          event.preventDefault();
          handleButtonRelease("button",event);
          console.log(event.code, "up")

          break;
        default:
          break;
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, [isButtonPressed]); // Make sure to include isButtonPressed in the dependency array


  return (
    <div className="joystick">
      <div>
        <button
          onMouseDown={(e) => {
            handleButtonPress("up", e);
            // setImgNumber(1);
          }}
          onTouchStart={(e) => {
            handleButtonPress("up", e);
            // setImgNumber(1);
          }}
          onMouseUp={(e) => handleButtonRelease("up", e)}
          onTouchEnd={(e) => handleButtonRelease("up", e)}
        >
          Up
        </button>
      </div>
      <div className="direction-buttons">
        <button
          onMouseDown={(e) => {
            handleButtonPress("left", e);
            // setImgNumber(0);
          }}
          onTouchStart={(e) => {
            handleButtonPress("left", e);
            // setImgNumber(0);
          }}
          onMouseUp={(e) => handleButtonRelease("left", e)}
          onTouchEnd={(e) => handleButtonRelease("left", e)}
        >
          Left
        </button>
        <button
          style={{ color: "red" }}
          onMouseDown={(e) => {
            handleButtonPress("button", e);
            // setImgNumber(0);
          }}
          onTouchStart={(e) => {
            handleButtonPress("button", e);
            // setImgNumber(0);
          }}
          onMouseUp={(e) => handleButtonRelease("button", e)}
          onTouchEnd={(e) => handleButtonRelease("button", e)}
        >
          Button
        </button>
        <button
          onMouseDown={(e) => {
            handleButtonPress("right", e);
            // setImgNumber(0);
          }}
          onTouchStart={(e) => {
            handleButtonPress("right", e);
            // setImgNumber(0);
          }}
          onMouseUp={(e) => handleButtonRelease("right", e)}
          onTouchEnd={(e) => handleButtonRelease("right", e)}
        >
          Right
        </button>
      </div>
      <div>
        <button
          onMouseDown={(e) => {
            handleButtonPress("down", e);
            // setImgNumber(1);
          }}
          onTouchStart={(e) => {
            handleButtonPress("down", e);
            // setImgNumber(1);
          }}
          onMouseUp={(e) => handleButtonRelease("down", e)}
          onTouchEnd={(e) => handleButtonRelease("down", e)}
        >
          Down
        </button>
      </div>
    </div>
  );
};
export default Joystick;
