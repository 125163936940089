import React, { useEffect, useState } from "react";
import "./adminPage.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import CameraSetting from "../components/cameraSetting/cameraSetting";
import UserTopUp from "../components/userTopUp/userTopUp";

import UserList from "../components/crudTable/user/list";
import EditUser from "../components/crudTable/user/update";

import MachineList from "../components/crudTable/machine/list";
import CreateMachine from "../components/crudTable/machine/create";
import EditMachine from "../components/crudTable/machine/update";

import PrizeList from "../components/crudTable/prize/list";
import CreatePrize from "../components/crudTable/prize/create";
import EditPrize from "../components/crudTable/prize/update";
import ListClaimRecord from "../components/crudTable/claimRecord/list";
import ListCoupon from "../components/crudTable/coupon/list";
import CreateCoupon from "../components/crudTable/coupon/create";

const AdminPage = () => {
  const location = useLocation();
  const pathname = location.pathname;

  const componentMapper = {
    "/admin": null,
    "/admin/camera": <CameraSetting />,
    "/admin/user": <UserList />,
    "/admin/top-up": <UserTopUp />,
    "/admin/machine": <MachineList />,
    "/admin/machine/create": <CreateMachine />,
    "/admin/prize": <PrizeList />,
    "/admin/prize/create": <CreatePrize />,
    "/admin/shipping": <ListClaimRecord />,
    "/admin/coupon": <ListCoupon />,
    "/admin/coupon/create": <CreateCoupon />,
  };

  let componentToRender = componentMapper[pathname];

  if (!componentToRender) {
    const id = pathname.split("/edit/")?.[1];
    if (pathname.startsWith("/admin/user/edit/")) {
      componentToRender = <EditUser id={id}/>;
    } else if (pathname.startsWith("/admin/machine/edit/")) {
      componentToRender = <EditMachine id={id}/>;
    } else if (pathname.startsWith("/admin/prize/edit/")) {
      componentToRender = <EditPrize id={id}/>;
    }
  }
 

  return (
    <div className="container-machine">
      <div style={{ margin: "10px" }}>
        <button style={{ marginRight: "10px" }}>
          <Link className="admin-button" style={{ marginRight: "10px" }} to="/admin/camera">
            Camera Setting
          </Link>
        </button >
        <button style={{ marginRight: "10px" }}>
          <Link className="admin-button" style={{ marginRight: "10px" }} to="/admin/machine">
            Machine Manage
          </Link>
        </button>
        <button style={{ marginRight: "10px" }}>
          <Link className="admin-button" style={{ marginRight: "10px" }} to="/admin/prize">
            Prize Manage
          </Link>
        </button>
        <button style={{ marginRight: "10px" }}>
          <Link className="admin-button" to="/admin/top-up" style={{ marginRight: "10px" }}>
            User Top-up
          </Link>
        </button>
        <button style={{ marginRight: "10px" }}>
          <Link className="admin-button" to="/admin/user" style={{ marginRight: "10px" }}>
            User Manage
          </Link>
        </button>
        <button style={{ marginRight: "10px" }}>
          <Link className="admin-button" to="/admin/shipping">
            Shipping
          </Link>
        </button>
        <button style={{ marginRight: "10px" }}>
          <Link className="admin-button" to="/admin/coupon">
            Coupon
          </Link>
        </button>
      </div>
      {componentToRender}
    </div>
  );
};
export default AdminPage;
