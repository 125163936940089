import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { TailSpin } from "react-loader-spinner";
import "./videoReceiver/videoReceiver.css";
import RTCMultiConnection from "rtcmulticonnection-react-js";

const LoadingSpinner = () => (
  <TailSpin
    color="white" // Customize the color
    height={50} // Adjust the height of the spinner
    width={50} // Adjust the width of the spinner
  />
);

const VideoContainer = styled.div`
`;

const Listenner = ({ machineID, camNumber }) => {
  const connectionCam1 = useRef();
  const connectionCam2 = useRef();
  const [myID, setMyID] = useState();
  const socket = useRef();
  const [stream1, setStream1] = useState();
  const [stream2, setStream2] = useState();
  const [camID1, setCamID1] = useState();
  const [camID2, setCamID2] = useState();
  const [isLoading1, setIsLoading1] = useState(true);
  const [isLoading2, setIsLoading2] = useState(true);
  const [joining1, setJoining1] = useState(false);
  const [joining2, setJoining2] = useState(false);


  // useEffect(() => {
  //   if (!socket.current) {
  //     socket.current = io.connect(process.env.REACT_APP_API_BASE_URL);
  //     socket.current.emit("INIT_USER", {
  //       machineID: machineID,
  //       token: localStorage.getItem("token"),
  //     });
  //     socket.current.on("id_report", (id) => {
  //       setMyID(id);
  //     });
  //     socket.current.on("cam_id", (payload) => {
  //       if (payload.number == 1) {
  //         setCamID1(payload.id);
  //       } else if (payload.number == 2) {
  //         setCamID2(payload.id);
  //       }
  //     });
  //   }
  // }, []);



  useEffect(() => {
    if (!connectionCam1.current) {
      connectionCam1.current = new RTCMultiConnection();

      connectionCam1.current.socketURL = process.env.REACT_APP_API_CAM + "/";

      connectionCam1.current.socketMessageEvent = "video-broadcast-demo";

      connectionCam1.current.session = {
        audio: false,
        video: false,
        oneway: true,
      };

      connectionCam1.current.sdpConstraints.mandatory = {
        OfferToReceiveAudio: false,
        OfferToReceiveVideo: false,
      };

      // first step, ignore default STUN+TURN servers
      connectionCam1.current.iceServers = [];

      // second step, set STUN url
      connectionCam1.current.iceServers.push({
        urls: process.env.REACT_APP_STUN_SERVER,
      });

      // last step, set TURN url (recommended)
      connectionCam1.current.iceServers.push({
        urls: process.env.REACT_APP_TURN_SERVER,
        username: process.env.REACT_APP_TURN_USER,
        credential: process.env.REACT_APP_TURN_PASSWORD,
      });

      connectionCam1.current.videosContainer = document.getElementById("videos-container");

      showCam1();

      connectionCam1.current.onstream = function (event) {
        var existing = document.getElementById(event.streamid);
        if (existing && existing.parentNode) {
          existing.parentNode.removeChild(existing);
        }

        event.mediaElement.removeAttribute("src");
        event.mediaElement.removeAttribute("srcObject");
        event.mediaElement.muted = true;
        event.mediaElement.volume = 0;

        setStream1(event.stream);

      };

      connectionCam1.current.onstreamended = function (event) {
        var mediaElement = document.getElementById(event.streamid);
        if (mediaElement) {
          mediaElement.parentNode.removeChild(mediaElement);

          if (
            event.userid === connectionCam1.sessionid &&
            !connectionCam1.current.isInitiator
          ) {
            alert(
              "Broadcast is ended. We will reload this page to clear the cache."
            );
            window.location.reload();
          }
        }
      };

      connectionCam1.current.onMediaError = function (e) {
        connectionCam1.join(connectionCam1.sessionid);
      };

      // connectionCam2.current = connectionCam1.current
    }
    return ()=>{
      connectionCam1.current.getAllParticipants().forEach(function(participantId) {
        console.log(participantId)
        connectionCam1.current.disconnectWith( participantId );
      });
      connectionCam1.current.closeSocket();
      console.log("remove")
    }
  }, []);

  const showCam1 = () => {
    connectionCam1.current.sdpConstraints.mandatory = {
      OfferToReceiveAudio: false,
      OfferToReceiveVideo: false,
    };
    if(joining1){
      return
    }
    setJoining1(true)
    connectionCam1.current.join(`${machineID}1`, function () {
      console.log(connectionCam1.current.sessionid);
      setJoining1(false)
    });
  };

  useEffect(() => {
    if (!connectionCam2.current) {
      connectionCam2.current = new RTCMultiConnection();

      connectionCam2.current.socketURL = process.env.REACT_APP_API_CAM + "/";

      connectionCam2.current.socketMessageEvent = "video-broadcast-demo";

      connectionCam2.current.session = {
        audio: false,
        video: false,
        oneway: true,
      };

      connectionCam2.current.sdpConstraints.mandatory = {
        OfferToReceiveAudio: false,
        OfferToReceiveVideo: false,
      };

      // first step, ignore default STUN+TURN servers
      connectionCam2.current.iceServers = [];

      // second step, set STUN url
      connectionCam2.current.iceServers.push({
        urls: process.env.REACT_APP_STUN_SERVER,
      });

      // last step, set TURN url (recommended)
      connectionCam2.current.iceServers.push({
        urls: process.env.REACT_APP_TURN_SERVER,
        username: process.env.REACT_APP_TURN_USER,
        credential: process.env.REACT_APP_TURN_PASSWORD,
      });

      connectionCam2.current.videosContainer = document.getElementById("videos-container");
      

      showCam2();

      connectionCam2.current.onstream = function (event) {
        var existing = document.getElementById(event.streamid);
        if (existing && existing.parentNode) {
          existing.parentNode.removeChild(existing);
        }

        event.mediaElement.removeAttribute("src");
        event.mediaElement.removeAttribute("srcObject");
        event.mediaElement.muted = true;
        event.mediaElement.volume = 0;

        setStream2(event.stream);

      };

      connectionCam2.current.onstreamended = function (event) {
        console.log("end")
        // handleReconnection()


        // var mediaElement = document.getElementById(event.streamid);
        // if (mediaElement) {
        //   mediaElement.parentNode.removeChild(mediaElement);

        //   if (
        //     event.userid === connectionCam2.current.sessionid &&
        //     !connectionCam2.current.isInitiator
        //   ) {
        //     alert(
        //       "Broadcast is ended. We will reload this page to clear the cache."
        //     );
        //     window.location.reload();
        //   }
        // }
      };

      connectionCam2.current.onMediaError = function (e) {
        connectionCam2.join(connectionCam2.sessionid);
      };

    }
    return ()=>{
      connectionCam2.current.getAllParticipants().forEach(function(participantId) {
        console.log(participantId)
        connectionCam2.current.disconnectWith( participantId );
      });
      connectionCam2.current.closeSocket();
      console.log("remove")
    }
  }, []);

  const showCam2 = () => {
    console.log("join")
    connectionCam2.current.sdpConstraints.mandatory = {
      OfferToReceiveAudio: false,
      OfferToReceiveVideo: false,
    };
    if(joining2){
      return
    }
    setJoining2(true)
    connectionCam2.current.join(`${machineID}2`, function () {
      console.log(connectionCam2.current.sessionid);
      setJoining2(false)
    });
  };

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (socket.current) {
        socket.current.emit("remove_user", { machineID: machineID });
      }
    };

    window?.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window?.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [socket.current, machineID]);

  useEffect(() => {
    // Update the video elements when stream1 or stream2 changes
    if (stream1 && camNumber === 0) {
      const videoRef = document.getElementById("video1"); // Get the video element by ID
      if (videoRef) {
        videoRef.srcObject = stream1;
        // Check if the video is ready to show
        videoRef.onloadeddata = () => {
          setIsLoading1(false);
        };
      }
    }
    if (stream2 && camNumber === 1) {
      const videoRef = document.getElementById("video2"); // Get the video element by ID
      if (videoRef) {
        videoRef.srcObject = stream2;
        videoRef.onloadeddata = () => {
          setIsLoading2(false);
        };
      }
    }
  }, [stream1, stream2, camNumber]);

  // useEffect(() => {
  //     const checkVideo1Size = (videoRef) => {
  //       if (videoRef && (videoRef.videoWidth < 100 || videoRef.videoHeight < 100)) {
  //         if(!joining1){
  //             console.log("Video size is 0. Attempting to reconnect....");
  //             setTimeout(() => {
  //               console.log(`Reconnecting attempt`);
  //               showCam1()
  //             }, 1000);
  //           }
  //       } else {
  //           console.log("Video size:", videoRef.videoWidth, videoRef.videoHeight);
  //       }
  //       requestAnimationFrame(() => checkVideo1Size(videoRef));
  //     };
  //     const checkVideo2Size = (videoRef) => {
  //       if (videoRef && (videoRef.videoWidth < 100 || videoRef.videoHeight < 100)) {
  //         if(!joining2){
  //             console.log("Video size is 0. Attempting to reconnect...");
  //             setTimeout(() => {
  //               console.log(`Reconnecting attempt`);
  //               showCam2()
  //             }, 1000);
  //           }
  //       } else {
  //           console.log("Video size:", videoRef.videoWidth, videoRef.videoHeight);
  //       }
  //       requestAnimationFrame(() => checkVideo2Size(videoRef));
  //     };
  
  //     const videoRef1 = document.getElementById("video2"); // Get the video element by ID
  //     if (videoRef1) {
  //       videoRef1.onloadeddata = () => {
  //         console.log("call check")
  //         checkVideo2Size(videoRef1)
  //       }
  //     }
  //     const videoRef2 = document.getElementById("video1"); // Get the video element by ID
  //     if (videoRef2) {
  //       videoRef2.onloadeddata = () => {
  //         checkVideo1Size(videoRef2)
  //       }
  //     }
  // }, []);

  return (
    <VideoContainer className="videoWrapper" id="videos-container">
      {camNumber === 0 ? (
        stream1 ? (
          <>
            {isLoading1 ? (
              <div className="BlankContainer">
                <LoadingSpinner />
              </div>
            ) : null}
            <video
              className={`video-machine ${isLoading1 ? "hidden" : ""}`}
              id="video1"
              playsInline
              autoPlay
              muted
            />
          </>
        ) : (
          <div className="BlankContainer">
            <LoadingSpinner />
          </div>
        )
      ) : null}
      {camNumber === 1 ? (
        stream2 ? (
          <>
            {isLoading2 ? (
              <div className="BlankContainer">
                <LoadingSpinner />
              </div>
            ) : null}
            <video
              className={`video-machine ${isLoading2 ? "hidden" : ""}`}
              id="video2"
              playsInline
              autoPlay
              muted
            />
          </>
        ) : (
          <div className="BlankContainer">
            <LoadingSpinner />
          </div>
        )
      ) : null}
    </VideoContainer>
  );
};

export default Listenner;
