import React from "react";
import Modal from "react-modal";
import { useState } from "react";
import controllerPNG from "../../resorce/controller.PNG";
import timmerPNG from "../../resorce/Timmer.PNG";
import boxPNG from "../../resorce/box.PNG";
import goalPNG from "../../resorce/goal.PNG";
import guaranteePNG from "../../resorce/guarantee.PNG";

const HowToPlay = ({ isShowHowToPlay, setIsShowHowToPlay }) => {
  const [page, setPage] = useState(0);
  const [notShowTutorialAgain, setNotShowTutorialAgain] = useState(false);
  const pages = [
    <>
      <p style={{ color: "black" }}>
        กดปุ่ม <button disabled={true}>play</button>{" "}
        จากนั้นจะมีปุ่มควบคุมและเวลาปรากฎ
      </p>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <img src={controllerPNG} style={{ width: "40%", padding: "3%" }}></img>
        <img src={timmerPNG} style={{ width: "40%", padding: "3%" }}></img>
      </div>
      <p style={{ color: "black" }}>
        สามารถกดควบคุมทิศทาง
        และกดปุ่มกลางครั้งแรกเพื่อนำขาคีบลงครั้งที่สองเพื่อหนีบขาคีบ
      </p>
    </>,
    <>
      <p style={{ color: "black" }}>
        เป้าหมายคือ คีบสินค้าให้ลงช่องรับสินค้า
        จากนั้นก็แลกรับสินค้าได้เลย
      </p>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <img src={boxPNG} style={{ width: "120px", padding: "3%" }}></img>
        <img src={goalPNG} style={{ width: "120px", padding: "3%" }}></img>
      </div>
    </>,
    <>
      <p style={{ color: "black" }}>
        เมื่อสินค้าลงช่อง หรือสะสมหลอด Accumulate Price ครบ
        สามารถแลกสินค้าตามหน้าตู้ได้ที่{" "}
        <button disabled={true}>จัดส่งสินค้า</button> บนแถบเมนูด้านบน
      </p>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <img src={guaranteePNG} style={{ width: "200px", padding: "3%" }}></img>
      </div>
      <p style={{ color: "red" }}>
        ปล.เมื่อสินค้าลงช่องแล้วหลอด Accumulate Price จะถูกรีเซตเป็น 0 อีกครั้ง
      </p>
    </>,
  ];
  return (
    <Modal
      className="modal-nav"
      isOpen={isShowHowToPlay}
      onAfterOpen={() => setIsShowHowToPlay(true)}
      onRequestClose={() => setIsShowHowToPlay(false)}
      contentLabel="Example Modal"
    >
      <h2>กติกาการเล่น</h2>
      <div style={{border:"1px solid black", borderRadius:"10px", padding:"10px"}}>{pages[page]}</div>
      {/* <div style={{ color: "blue" }}>
        <input
          type="checkbox"
          value={notShowTutorialAgain}
          onClick={() => {
            setNotShowTutorialAgain(!notShowTutorialAgain);
          }}
        ></input>
        do not show again
      </div> */}
      <div style={{ display: "flex" }}>
        <button
          onClick={() => setPage((page + 1) % 3)}
          style={{ margin: "3%", display: page+1 === pages.length? "none" : "block", width:"90px"}}
        >
          ถัดไป
        </button>
        <button
          onClick={() => {
            setIsShowHowToPlay(false);
            localStorage.setItem("isNotTutorialAgain", notShowTutorialAgain);
          }}
          style={{ margin: "3%", width:"90px" }}
        >
          ปิด
        </button>
      </div>
    </Modal>
  );
};

export default HowToPlay;
