import React, { useState, useEffect, useRef } from "react";
import "./machinesPage.css";
import { useNavigate } from "react-router-dom";
import Slideshow from "../components/slideImage/slideImage";
import styled from 'styled-components';
import coinIcon from '../resorce/coin-icon.png'
import { FFmpeg } from '@ffmpeg/ffmpeg'
import { fetchFile  } from '@ffmpeg/util'
import axiosClient from "../util/AxiosClient";

const ffmpeg = new FFmpeg();

const FloatingSidebar = styled.div`
  position: fixed;
  right: 8px; /* Or left: 0; for the left side */
  width: 60vw;
  max-width: 400px;
  background-color: #24292e;
  box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  border-radius: 15px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px;
  text-align: center;

  @media (max-width: 375px) {
    p {
      font-size: smaller
    }
  }
`;


const MachinesPage = () => {
  const navigate = useNavigate();
  const [machines, setMachines] = useState([]);
  const [typesWithLength, setTypesWithLength] = useState();
  const token = localStorage.getItem("token");
  const [showItemLimit,setShowItemLimit] = useState();
  const [lastWinVideo, setLastWinVideo] = useState()
  const [isShowWinner, setIsShowWinner] = useState(true);
  const containerRefs = useRef([]);
  const [videoUrl,setVideoUrl] = useState(null)
  useEffect(() => {
    getPrizes().then((res) => {
      if (res) {
        const classify_prize = {};
        res?.data.forEach((prize) => {
          if (prize.isEnable){
            const type = prize.name.trim().split(" ")[0];
            if (classify_prize[type]) {
              classify_prize[type].push(prize);
            } else {
              classify_prize[type] = [prize];
            }
            cacheMachineImage(prize);
          }
        });
        for (const type in classify_prize) {
          if (
            classify_prize[type].length === 0
          ) {
            delete classify_prize[type];
            continue;
          }
          classify_prize[type] = classify_prize[type].sort((a, b) => {
            if (a.isOutOfStock && !b.isOutOfStock) return 1;
            if (!a.isOutOfStock && b.isOutOfStock) return -1;

            if (a.price !== b.price) {
              return a.price - b.price;
            } else {
              return a.name.localeCompare(b.name);
            }
          });
        }
        let l = Object.keys(classify_prize).map((type) => ({
          isExpand: false,
          type,
          length: classify_prize[type].length,
        }));

        // // Sort the types by the length of machines in descending order
        // l.sort((a, b) => b.length - a.length);
        // console.log(l);

        // Separate the special type
        const specialType = l.find(
          (typeObj) => typeObj.type === "ตู้ฝึกคีบ"
        );
        if (specialType) {
          l = l.filter((typeObj) => typeObj.type !== specialType.type);
        }

        // Sort the types (excluding the special type) by the length of machines in descending order
        l.sort((a, b) => b.length - a.length);

        // Prepend the special type to the sorted array
        if (specialType) {
          l.push(specialType);
        }
        setTypesWithLength(l);
        setMachines(classify_prize);
      }
    });
    getLastWinVideo().then((res)=>{
      console.log(res)
      setLastWinVideo(res)
    })
  }, []);

  useEffect(() => {
    // Loop through all container refs and compute column counts
    typesWithLength?.forEach((_, index) => {
      const currentContainer = containerRefs.current[index];
      if (currentContainer) {
        const computedStyle = window.getComputedStyle(currentContainer);
        const columns = computedStyle.getPropertyValue('grid-template-columns');
        const columnCount = columns.split(' ').length;

        // Update the state with the column count for this container
        setShowItemLimit(columnCount*4-1);
      }
    });
  }, [typesWithLength]); // Run when typesWithLength changes

  const getPrizes = async () => {
    try {
      const response = await axiosClient.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/prize/user`,

        {
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      console.log(error);
      // Handle login failure
    }
  };

  const getLastWinVideo = async () => {
    try {
      const response = await axiosClient.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/play-record/last`,

        {
          withCredentials: true,
        }
      );
      return response.data.data;
    } catch (error) {
      console.log(error);
      // Handle login failure
    }
  };

  const handleClickMachine = (prizeId) => {
    if (!token) {
      navigate("/login");
    } else {
      navigate(`play/${prizeId}`);
    }
  };

  const compressImage = (blob, quality = 0.7) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = (event) => {
        const img = new Image();
        img.src = event.target.result;
  
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
  
          // Resize the image (reduce its size)
          const maxWidth = 300; // Set the max width to reduce the size
          const scaleSize = maxWidth / img.width;
          canvas.width = maxWidth;
          canvas.height = img.height * scaleSize;
  
          ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
  
          // Convert the image to a compressed Base64 string
          const compressedBase64 = canvas.toDataURL('image/jpeg', quality); // Adjust quality (0.7 is 70%)
          resolve(compressedBase64);
        };
  
        img.onerror = (error) => {
          reject(error);
        };
      };
      reader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const convertBlobToBase64 = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };

  
  const resizeVideoToBase64 = async (videoBlob, width, height) => {
    // Load ffmpeg if not already loaded
    if (!ffmpeg.loaded) {
      await ffmpeg.load();
    }

    // Create unique filenames
    const inputFileName = 'input.mp4';
    const outputFileName = 'output.mp4';

    // Write the video blob to FFmpeg's file system
    await ffmpeg.writeFile(inputFileName, await fetchFile(videoBlob));

    // Resize the video
    await ffmpeg.exec(['-i', inputFileName, '-vf', `scale=${width}:${height}`, outputFileName]);

    // Read the processed video file
    const data = await ffmpeg.readFile(outputFileName);

    // Convert processed video to Blob
    const processedVideoBlob = new Blob([data.buffer], { type: 'video/mp4' });

    // Convert Blob to Base64
    const base64Video = await convertBlobToBase64(processedVideoBlob);

    return base64Video;
  };

  useEffect(() => {
    const cachedVideo = localStorage.getItem('lastWinVideo');
    const cachedWonAt = localStorage.getItem('cachedWonAt');

    // Clear cache if cachedVideo is invalid
    if (!cachedVideo || cachedVideo === 'undefined') {
      localStorage.removeItem('cachedVideoUrl');
      localStorage.removeItem('cachedWonAt');
    }

    if (lastWinVideo) {
      if (cachedVideo && cachedWonAt === lastWinVideo.wonAt) {
        setVideoUrl(cachedVideo);
      } else {
        fetch(lastWinVideo.videoUrl)
          .then((response) => response.blob())
          .then(async (blob) => {
            const resizedBase64Video = await resizeVideoToBase64(blob, 480, 360);
            console.log(resizedBase64Video);
            setVideoUrl(resizedBase64Video);
            localStorage.setItem('lastWinVideo', resizedBase64Video);
            localStorage.setItem('cachedWonAt', lastWinVideo.wonAt);
          })
          .catch((error) => {
            console.error('Error fetching or converting video:', error);
          });
      }
    }
  }, [lastWinVideo]);

  useEffect(() => {
    if(!localStorage.getItem("lastRefresh")){
      localStorage.setItem("lastRefresh", Date.now());
    }
    const timestamp = localStorage.getItem("lastRefresh");
    if (Date.now() - timestamp > 15*60*1000) {
      localStorage.setItem("lastRefresh", Date.now());
      window.location.reload()
    }
  }, []);


  const fetchAndCacheImage = async (machine) => {
    try {
      const response = await fetch(machine.image);
      const blob = await response.blob();
      const compressedBase64 = await compressImage(blob, 0.5); // Adjust quality here
  
      // Store both the image and the current timestamp in localStorage
      localStorage.setItem(machine._id, JSON.stringify({
        image: compressedBase64,
        timestamp: Date.now(),
      }));
    } catch (error) {
      console.error("Failed to cache image", error);
    }
  };

  const cacheMachineImage = async (machine) => {
    const cachedData = localStorage.getItem(machine._id);
    
    // Check if cached data exists
    if (!cachedData || cachedData === 'undefined') {
      await fetchAndCacheImage(machine);
    } else {
      // Parse cached data
      const { timestamp } = JSON.parse(cachedData);

      // Check if the image is older than 1 hour (3600000 milliseconds)
      if (Date.now() - timestamp > 3600000) {
        await fetchAndCacheImage(machine);
      }
    }
  };


  

  return (
    <div className="container-machine-page">
      {lastWinVideo && isShowWinner && videoUrl ?
        <FloatingSidebar>
        <Header>
            <p style={{ color: "white", margin: "0px" }}>
              ผู้รับสินค้าล่าสุด: <p style={{ color: "#f1c40f", margin: "0px" }}>{lastWinVideo.winnerName}</p>
            </p>
            <p style={{ color: "white", margin: "0px" }}>
              สินค้า: <p style={{ color: "#f1c40f", margin: "0px" }}>{lastWinVideo.prizeName}</p>
            </p>
          <button onClick={()=>{setIsShowWinner(false)}}>ปิด</button>
        </Header>
        <div
          style={{
            height: "75vw",
            maxHeight: "500px",
          }}
        >
          <video
            className="video-rotate"
            style={{
              justifyContent:"center",
              height:"55vw",
              objectFit: "cover",
              position: "fixed",
              maxHeight: "367px",
            }}
            loop
            autoPlay
            playsInline
            muted
            src={videoUrl}
          />
        </div>
        </FloatingSidebar>:
      null}
      <div className="imageBanner">
        <Slideshow />
      </div>
      <div style={{marginBottom:"30px"}}>
      <div  style={{
          maxWidth: "1500px",
          margin: "20px auto 0px auto",
          padding: "20px 20px 0px 20px",
          backgroundColor: "#ffffffae",
          width: "90%",
          borderRadius: "1.5%"}}>
          <h2 style={{textAlign:"left", margin:"0px"}}>รายการสินค้า</h2>
        </div>
        {typesWithLength ? (
          typesWithLength.map((e, index) => (
            <div className="container-machine" style={{borderTop:"1px dashed #333"}}>
              <h4 className="page-title">{e.type}</h4>
              <div className="card-container" ref={el => (containerRefs.current[index] = el)}>
                {showItemLimit ? 
                  !e.isExpand && e.length > showItemLimit ? (
                    <>
                      {machines[e.type].slice(0, showItemLimit).map((machine) =>
                        <div
                          className="card-machine"
                          key={machine._id}
                          onClick={() => !machine.isOutOfStock ? handleClickMachine(machine._id): null}
                          style={{
                            position: 'relative',
                            opacity: machine.isOutOfStock ? 0.7 : 1, // Gray out the card when out of stock
                          }}
                        >
                           {machine.isOutOfStock ? (
                            <div style={{
                              position: 'absolute',
                              top: '50%',
                              left: '50%',
                              transform: 'translate(-50%, -50%)',
                              color: 'red',
                              fontWeight: 'bold',
                              fontSize: '24px',
                              backgroundColor: 'rgba(255, 255, 255, 0.8)',
                              padding: '10px',
                              borderRadius: '8px',
                            }}>
                              สินค้าหมด
                            </div>
                          ) : null}
                          <img
                            className="card-image"
                            src={JSON.parse(localStorage.getItem(machine?._id))?.image || machine.image}
                            alt={machine.name}
                            
                          />
                          <div className="card-content">
                            <h3 className="card-title">{machine.name}</h3>
                            <p className="card-description">
                              {machine.description}
                            </p>
                            <p className="card-price"> 
                              <img src={coinIcon} alt="" style={{
                                width: "20px",
                                height: "20px",
                                marginRight: "8px",
                                verticalAlign: "middle"}} /> 
                              {machine.price} coin/play
                            </p>
                          </div>
                        </div>
                      )}
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "flex-end",
                        }}
                      >
                        <button
                          onClick={() => {
                            setTypesWithLength((prevState) => {
                              const updatedTypes = prevState.map((item) => {
                                if (item.type === e.type) {
                                  return { ...item, isExpand: true };
                                }
                                return item;
                              });
                              return updatedTypes;
                            });
                          }}
                          style={{
                            cursor: "pointer",
                            fontSize: "18px",
                          }}
                        >
                          show more...
                        </button>
                      </div>
                    </>
                  ) : (
                    machines[e.type].map((machine) =>
                      <div
                        className="card-machine"
                        key={machine._id}
                        onClick={() => !machine.isOutOfStock ? handleClickMachine(machine._id): null}
                        style={{
                          position: 'relative',
                          opacity: machine.isOutOfStock ? 0.7 : 1, // Gray out the card when out of stock
                        }}
                      >
                        {machine.isOutOfStock ? (
                          <div style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            color: 'red',
                            fontWeight: 'bold',
                            fontSize: '24px',
                            backgroundColor: 'rgba(255, 255, 255, 0.8)',
                            padding: '10px',
                            borderRadius: '8px',
                          }}>
                            สินค้าหมด
                          </div>
                        ) : null}
                        <img
                          className="card-image"
                          src={JSON.parse(localStorage.getItem(machine?._id))?.image || machine.image}
                          alt={machine.name}
                        />
                        <div className="card-content">
                          <h3 className="card-title">{machine.name}</h3>
                          <p className="card-description">
                            {machine.description}
                          </p>
                          <p className="card-price"> 
                            <img src={coinIcon} alt="" style={{
                              width: "20px",
                              height: "20px",
                              marginRight: "8px",
                              verticalAlign: "middle"}} /> 
                            {machine.price} coin/play
                          </p>
                        </div>
                      </div>
                    )
                  )
                :null}
              </div>
            </div>
          ))
        ) : (
          <h2>Loading...</h2>
        )}
      </div>
    </div>
  );
};

export default MachinesPage;
